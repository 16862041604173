<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.changeNo"
                      name="order_no"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="采购单编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.orderNo"
                      name="order_no"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.name"
                      name="order_no"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

<!--              <b-col-->
<!--                  cols="12"-->
<!--                  md="4"-->
<!--              >-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    label="订单来源:"-->
<!--                    label-for="source"-->
<!--                    label-size="sm"-->
<!--                >-->
<!--                  <v-select-->
<!--                      id="source"-->
<!--                      :options="getCodeOptions('sales_order_source')"-->
<!--                      v-model="condition.source"-->
<!--                      name="source"-->
<!--                      class="select-size-sm"-->
<!--                      placeholder="请选择订单来源"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->

                            <b-col md="4" cols="12" class="mb-2">
                              <xy-input-button label="创建日期"
                                               type="time"
                                               v-on:change="fromChildren($event,['addTime'])"
                                               :params="['addTime']"
                                               :value="condition.addTime"
                              >
                              </xy-input-button>
                            </b-col>

                            <b-col md="4" cols="12" class="mb-1"
                            >
                              <xy-input-button
                                  label="创建人"
                                  type="input"
                                  v-on:change="fromChildren($event,['creatorName','creatorId'])"
                                  :params="['creatorName','creatorId']"
                                  :value="condition.creatorName"
                                  modalName="创建人"
                                  placeholder="点击选择创建人"
                              >
                              </xy-input-button>
                            </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>


      </div>

      <xy-tab
          :tabs="getCodeOptions('purchase_order_changecost_status').filter((datas)=>{return datas.value!=4;})"
          :statusValue="status"
          v-on:emitTabs="function($event) {tabStatus = $event}"
      />

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(change_id)="data">
          <!--          <b-link-->
          <!--              :to="{ name: 'apps-purchaseorderchangecost-edit', query: { id: data.item.id } }"-->
          <!--              class="font-weight-bold d-block text-nowrap"-->
          <!--          >-->
          <!--            -->
          <!--          </b-link>-->
          #{{ data.item.change_id }}
        </template>

        <template #cell(company_name)="data">
          {{ data.item.ext.company_name }}
        </template>
        <template #cell(supplier_name)="data">
          {{ data.item.ext.supplier_name }}
        </template>

        <!-- Column: Type -->
        <template #cell(${classVar}_type)="data">
          {{ getCodeLabel('purchaseorderchangecost_type', data.item.purchaseorderchangecost_type) }}
        </template>

        <template #cell(status)="data">
          <!--          <span :class="'text-'+getCode('contract_status', data.item.status).color">{{getCodeLabel('contract_status', data.item.status)}}</span>-->
          <b-badge
              pill
              :variant="`light-${getCodeColor('purchase_order_changecost_status', data.item.status)}`"
          >
            {{ getCodeLabel("purchase_order_changecost_status", data.item.status) }}
          </b-badge>
        </template>

        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(modify_time)="data">
          {{ toDate(data.item.modify_time) }}
        </template>

        <template #cell(updator)="data">
          {{ getCodeLabel('user', data.item.updator) }}
        </template>

        <template #cell(tax_rate)="data">
          {{ getCodeLabel('tax_rate', data.item.tax_rate) }}
        </template>

        <template #cell(attachments)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments'"
                             :id="data.item.attachments"
                             :object_type="'sales_order'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
        </template>

        <template #cell(memo)="data">
          <div>
            {{!isEmpty(data.item.memo)?data.item.memo.substring(0,10):'' }}
            <feather-icon
                v-if="!isEmpty(data.item.memo)?data.item.memo.length > 10:false"
                icon="EyeIcon"
                size="21"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+data.item.memo+
              '</div>'"
                variant="success"
            />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="changeStatus(data.item,2,'提交审核')" v-if="data.item.status==1">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteItem(data.item)" v-if="data.item.status==1">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,3,'审核通过')" v-if="data.item.status==2&&(user.role_id==12||user.role_id==14||user.role_id==11)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">审核通过</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,1,'驳回')" v-if="data.item.status==2">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">驳回</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import purchaseorderchangecostUseList from './purchaseorderchangecostUseList'
import purchaseorderchangecostStore from './purchaseorderchangecostStore'
import {useToast} from "vue-toastification/composition";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import Ripple from "vue-ripple-directive";
import XyTab from "@/views/components/xy/XyTab";
import XyInputButton from "@/views/components/xy/XyInputButton";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    XyInputButton,
    XyTab,
    AttachmentUpload,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('purchaseorderchangecost/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.condition = {}
      this.refetchData();
    }
  },
  data() {
    return {
      advanced_search_modal: false,
      status:1,
      user: {},
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchaseorderchangecost')) store.registerModule('purchaseorderchangecost', purchaseorderchangecostStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseorderchangecost')) store.unregisterModule('purchaseorderchangecost')
    })

    const changeStatus = function (params, status, information) {
      if (confirm('确认'+information+'吗?')) {
        store.dispatch('purchaseorderchangecost/changeStatus', {id: params.change_id, status: status}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const deleteItem = function (item) {
      if (confirm('确认删除吗?')) {
        store.dispatch('purchaseorderchangecost/deleteItem', {id: item.change_id}).then(res => {
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      tabStatus,

      // UI
    } = purchaseorderchangecostUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      tabStatus,
      isEmpty,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      changeStatus,
      deleteItem,
      advanced_search,
      fromChildren,
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
